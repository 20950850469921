import axios from 'axios';

const ADPI_BASE_URL = process?.env?.ADPI_BASE_URL ?? 'https://adpi.klantcloud.nl/api';

const config = {
    headers: {
        'API-KEY': '',
        'VESTIGING-KEY': '',
    },
};

export const calculateMaximumMortgage = async function ( payload, apiKey, officeId ) {
    try {
        config.headers['API-KEY'] = apiKey;
        config.headers['VESTIGING-KEY'] = officeId;

        const { data } = await axios.post(`${ ADPI_BASE_URL }/calculate-maximum-mortgage-external`, payload, config);

        return data;
    } catch (error) {
        console.log(error);
    }
};

export const calculateExpense = async function ( payload, apiKey, officeId ) {
    try {
        config.headers['API-KEY'] = apiKey;
        config.headers['VESTIGING-KEY'] = officeId;

        const { data } = await axios.post(`${ ADPI_BASE_URL }/calculate-expense-external`, payload, config);

        return data;
    } catch (error) {
        console.log(error);
    }
};
