<template>
    <div class="relative overflow-hidden bg-white pt-16 pb-16">
        <div class="relative">
            <div class="lg:mx-auto lg:max-w-7xl grid grid-cols-1 lg:grid-cols-2 gap-4 p-8 md:gap-20">
                <form
                    action="#"
                    class="grid grid-cols-1 justify-self-center gap-8 w-full"
                    @submit.prevent
                >
                    <label class="block text-sm font-bold text-gray-500  flex flex-col">
                        {{ langcode === 'nl' ? 'Je bruto jaarsalaris*' : 'Your gross income*' }}

                        <input
                            v-model.number="formSchema.yearlyIncome"
                            class="border border-gray-200 rounded-md shadow-sm mt-1 text-gray-900"
                            min="0"
                            name="yearlyIncome"
                            placeholder="40000"
                            required
                            step="1"
                            type="number"
                        />
                    </label>

                    <label class="block text-sm font-bold text-gray-500  flex flex-col">
                        {{ langcode === 'nl' ? 'Je partner\'s bruto jaarsalaris' : 'Your partner\'s gross income' }}

                        <input
                            v-model.number="formSchema.yearlyIncomePartner"
                            class="border border-gray-200 rounded-md shadow-sm mt-1 text-gray-900"
                            min="0"
                            name="partnerYearlyIncome"
                            placeholder="optioneel"
                            required
                            step="1"
                            type="number"
                        />
                    </label>

                    <label class="block text-sm font-bold text-gray-500  flex flex-col">
                        {{ langcode === 'nl' ? ' Rente*' : 'Interest rate*' }}

                        <input
                            v-model.number="formSchema.interestRate"
                            class="border border-gray-200 rounded-md shadow-sm mt-1 text-gray-900"
                            min="0"
                            name="interestRate"
                            placeholder="3,8"
                            required
                            step="1"
                            type="number"
                        />
                    </label>

                    <label class="block text-sm font-bold text-gray-500  flex flex-col">
                        {{ langcode === 'nl' ? 'Aankoopprijs / marktwaarde' : 'Purchase price' }}

                        <input
                            v-model.number="formSchema.valueForLtv"
                            class="border border-gray-200 rounded-md shadow-sm mt-1 text-gray-900"
                            min="0"
                            name="valueForLtv"
                            placeholder="600000"
                            required
                            step="1"
                            type="number"
                        />
                    </label>

                    <label class="block text-sm font-bold text-gray-500  flex flex-col">
                        {{ langcode === 'nl' ? 'Oorspronkelijke studieschuld (DUO)' : 'Student loan' }}

                        <input
                            v-model.number="formSchema.amountNonDeductibleStudentLoan"
                            class="border border-gray-200 rounded-md shadow-sm mt-1 text-gray-900"
                            min="0"
                            name="amountNonDeductibleStudentLoan"
                            placeholder="4000"
                            required
                            step="1"
                            type="number"
                        />
                    </label>

                    <div
                        v-if="studentLoanIsFilled"
                        class="flex flex-wrap"
                    >
                <span class="block text-sm font-bold text-gray-500 w-full">
                    {{ langcode === 'nl' ? 'Studieschuld stelsel' : 'Student loan program' }}
                </span>

                        <div class="">
                            <label>
                                <input
                                    v-model="formSchema.studentLoanRegime"
                                    class="shadow-md"
                                    name="studentLoanRegime"
                                    type="radio"
                                    value="old"
                                />

                                {{ langcode === 'nl' ? 'Oud' : 'Old' }}
                            </label>
                        </div>

                        <div class="ml-4">
                            <label>
                                <input
                                    v-model="formSchema.studentLoanRegime"
                                    class="shadow-md"
                                    name="studentLoanRegime"
                                    type="radio"
                                    value="new"
                                />

                                {{ langcode === 'nl' ? 'Nieuw' : 'New' }}
                            </label>
                        </div>
                    </div>

                    <label class="block text-sm font-bold text-gray-500  flex flex-col">
                        {{ langcode === 'nl' ? 'BKR / totaal krediet' : 'Total credit' }}

                        <input
                            v-model.number="formSchema.amountNonDeductibleCredit"
                            class="border border-gray-200 rounded-md shadow-sm mt-1 text-gray-900"
                            min="0"
                            name="amountNonDeductibleCredit"
                            placeholder="4000"
                            required
                            step="1"
                            type="number"
                        />
                    </label>

                    <div class="flex flex-wrap">
                <span class="block text-sm font-bold text-gray-500 w-full">
                    {{ langcode === 'nl' ? 'Gewenste soort hypotheek' : 'Type of mortgage' }}
                </span>

                        <div class="">
                            <label>
                                <input
                                    v-model="formSchema.repaymentType"
                                    class="shadow-md"
                                    name="repaymentType"
                                    required
                                    step="1"
                                    type="radio"
                                    value="annuitair"
                                />

                                {{ langcode === 'nl' ? 'Annuitair' : 'Annuity' }}
                            </label>
                        </div>

                        <div class="ml-4">
                            <label>
                                <input
                                    v-model="formSchema.repaymentType"
                                    class="shadow-md"
                                    name="repaymentType"
                                    placeholder="bijv. 3,8"
                                    required
                                    step="1"
                                    type="radio"
                                    value="linear"
                                />

                                {{ langcode === 'nl' ? 'Lineair' : 'Linear' }}
                            </label>
                        </div>
                    </div>

                    <label class="block text-sm font-bold text-gray-500">
                        {{ langcode === 'nl' ? 'Energie label' : 'Energy label' }}

                        <select
                            v-model="formSchema.energyCertificate"
                            class="border text-gray-900 text-sm rounded-md block w-full p-2.5 shadow-sm"
                        >
                            <option
                                v-for="item in energyCertificateOptions"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.text }}
                            </option>
                        </select>
                    </label>
                </form>

                <!-- Output -->
                <div>
                    <div class="p-4 bg-blue-100 p-14 h-fit shadow-lg justify-self-center mt-16 rounded-md flex flex-wrap flex-col w-full max-w-2xl mx-auto lg:max-w-none">
                        <h2 class="text-3xl font-medium mb-8">
                            {{ langcode === 'nl' ? 'Hypotheek berekenen' : 'Mortgage calculation' }}
                        </h2>

                        <div>
                            <h2 class="text-xl">
                                {{ langcode === 'nl' ? 'Maximale hypotheek' : 'Maximum mortgage' }}
                            </h2>

                            <p class="font-bold text-4xl mb-8">
                                <span v-if="!isLoading">
                                    {{ formatNumber(maximumMortgage) }}
                                </span>

                                <LoadingIndicator
                                    v-if="isLoading"
                                    size="40"
                                />
                            </p>
                        </div>

                        <div>
                            <h2 class="text-xl">
                                {{ langcode === 'nl' ? 'Bruto maandelijkse kosten' : 'Gross monthly expenses' }}
                            </h2>

                            <p class="font-bold text-4xl mb-8">

                                <span v-if="!isLoading">
                                    {{ formatNumber(grossMonthlyExpense) }}
                                </span>

                                <LoadingIndicator
                                    v-if="isLoading"
                                    size="40"
                                />
                            </p>
                        </div>

                        <div>
                            <h2 class="text-xl">
                                {{ langcode === 'nl' ? 'Inbreng eigen middelen' : 'Contributions of own funds' }}
                            </h2>

                            <div>
                                <p class="font-bold text-4xl">
                                    <span v-if="!isLoading">
                                        {{ formatNumber(contributionOfOwnFunds.total) }}
                                    </span>

                                    <LoadingIndicator
                                        v-if="isLoading"
                                        size="40"
                                    />
                                </p>

                                <div v-if="contributionOfOwnFunds.total > 0">
                                    <hr class="my-2 h-1 bg-blue-200 border-0">

                                    <p class="flex gap-6 text-lg">
                            <span class="flex justify-end w-4/12 font-semibold">
                                {{ formatNumber(contributionOfOwnFunds.minimumOwnFunds) }}
                            </span>

                                        <span class="flex w-7/12">
                                {{ langcode === 'nl' ? 'Minimale eigen middelen' : 'Minimum own funds' }}
                            </span>
                                    </p>

                                    <p class="flex gap-6 text-lg">
                            <span class="flex justify-end w-4/12 font-semibold">
                                {{ formatNumber(contributionOfOwnFunds.taxAmount) }}
                            </span>

                                        <span class="flex w-7/12">
                                {{ langcode === 'nl' ? 'Belasting' : 'Taxes' }}
                            </span>
                                    </p>

                                    <p class="flex gap-6 text-lg">
                            <span class="flex justify-end w-4/12 font-semibold">
                                {{ formatNumber(contributionOfOwnFunds.brokerAmount) }}
                            </span>

                                        <span class="flex w-7/12">
                                {{ langcode === 'nl' ? 'Makelaarskosten' : 'Broker fee' }}
                            </span>
                                    </p>

                                    <p class="flex gap-6 text-lg">
                            <span class="flex justify-end w-4/12 font-semibold">
                                {{ formatNumber(contributionOfOwnFunds.appraisalFee) }}
                            </span>

                                        <span class="flex w-7/12">
                                {{ langcode === 'nl' ? 'Taxatiekosten' : 'Appraisal fee' }}
                            </span>
                                    </p>

                                    <p class="flex gap-6 text-lg">
                            <span class="flex justify-end w-4/12 font-semibold">
                                {{ formatNumber(contributionOfOwnFunds.mortgageAdviceFee) }}
                            </span>

                                        <span class="flex w-7/12">
                                {{ langcode === 'nl' ? 'Advieskosten' : 'Mortgage advice fee' }}
                            </span>
                                    </p>

                                    <p class="flex gap-6 text-lg">
                            <span class="flex justify-end w-4/12 font-semibold">
                                {{ formatNumber(contributionOfOwnFunds.notaryFee) }}
                            </span>

                                        <span class="flex w-7/12">
                                {{ langcode === 'nl' ? 'Notariskosten' : 'Notary fee' }}
                            </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';

    import { debounce } from '@/helpers/debounce';
    import { calculateExpense, calculateMaximumMortgage } from '@/helpers/adpi';
    import { APPRAISAL_FEE, BROKER_RATE, MORTGAGE_ADVICE_FEE, NOTARY_FEE, TAX_RATE } from '@/constants/Mortgage';

    import LoadingIndicator from '@/components/LoadingIndicator.vue';

    export default defineComponent({
        components: {
            LoadingIndicator
        },

        props: {
            langcode: {
                type: String,
                default: 'nl',
                required: false,
            },
            apiKey: {
                type: String,
                default: '',
                required: true,
            },
            officeId: {
                type: String,
                default: '',
                required: true,
            },
            brokerageFee: {
                type: String,
                default: '',
                required: false,
            },
            appraisalFee: {
                type: String,
                default: '',
                required: false,
            },
            mortgageAdviceFee: {
                type: String,
                default: '',
                required: false,
            },
            notaryFee: {
                type: String,
                default: '',
                required: false,
            },
        },

        data() {
            return {
                formSchema: {
                    yearlyIncome: null,
                    yearlyIncomePartner: null,
                    interestRate: null,
                    repaymentType: 'annuitair',
                    energyCertificate: '13 Nul-op-de-Meter',
                    valueForLtv: null,
                    amountNonDeductibleCredit: null,
                    amountNonDeductibleStudentLoan: null,
                    studentLoanRegime: 'old',
                    useNewNorm: true
                },

                energyCertificateOptions: [
                    { text: 'Nul-op-de-meter', value: '13 Nul-op-de-Meter' },
                    { text: 'Energie Neutraal', value: '12 Energie Neutraal' },
                    { text: 'A++++', value: '11 A++++' },
                    { text: 'A+++', value: '10 A+++' },
                    { text: 'A++', value: '01 A++' },
                    { text: 'A+', value: '02 A+' },
                    { text: 'A', value: '03 A' },
                    { text: 'B', value: '04 B' },
                    { text: 'C', value: '05 C' },
                    { text: 'D', value: '06 D' },
                    { text: 'E', value: '07 E' },
                    { text: 'F', value: '08 F' },
                    { text: 'G', value: '09 G' },
                ],

                isLoading: false,

                maximumMortgage: 0,

                grossMonthlyExpense: 0,

                errors: []
            };
        },

        methods: {
            getMaximumMortgage: debounce(async function () {
                try {
                    if ( //Required fields
                        this.formSchema.yearlyIncome &&
                        this.formSchema.interestRate &&
                        this.formSchema.repaymentType

                    ) {
                        this.isLoading = true;

                        this.maximumMortgage = await calculateMaximumMortgage(this.formSchema, this.apiKey, this.officeId);
                        await this.getExpense();

                        this.isLoading = false;
                    }

                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }
            }, 250),

            async getExpense() {
                try {
                    if ( //Required fields
                        this.formSchema.valueForLtv &&
                        this.maximumMortgage &&
                        this.formSchema.interestRate &&
                        this.formSchema.repaymentType

                    ) {
                        this.isLoading = true;

                        const amountDeductible = Math.min(this.maximumMortgage, this.formSchema.valueForLtv);

                        let payload = {
                            ...this.formSchema,
                            amountDeductible,
                        };

                        this.grossMonthlyExpense = await calculateExpense(payload, this.apiKey, this.officeId);

                        this.isLoading = false;
                    }
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                }
            },

            formatNumber: function ( number ) {
                if (!number && number !== 0) number = 0;

                return new Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                }).format(number);
            },

            stringToNumber(number) {
                // First, check if the input string is a valid number
                if (!parseFloat(number)) {
                    return NaN;
                }

                // If the string is a valid number, convert it to a number and return it
                return parseFloat(number);
            }
        },

        computed: {
            studentLoanIsFilled() {
                return this.formSchema.amountNonDeductibleStudentLoan > 0;
            },

            contributionOfOwnFunds() {
                if (this.formSchema.valueForLtv > 0) {
                    // Default values
                    const contributionOfOwnFunds = {
                        minimumOwnFunds: 0,
                        taxAmount: this.formSchema.valueForLtv * TAX_RATE,
                        brokerAmount: this.formSchema.valueForLtv * BROKER_RATE,
                        appraisalFee: APPRAISAL_FEE,
                        mortgageAdviceFee: MORTGAGE_ADVICE_FEE,
                        notaryFee: NOTARY_FEE,
                    };

                    // Overwrite default value if props has been given
                    if (this.brokerageFee && this.stringToNumber(this.brokerageFee)) {
                        contributionOfOwnFunds.brokerAmount = this.stringToNumber(this.brokerageFee)
                    }

                    if (this.appraisalFee && this.stringToNumber(this.appraisalFee)) {
                        contributionOfOwnFunds.appraisalFee = this.stringToNumber(this.appraisalFee)
                    }

                    if (this.mortgageAdviceFee && this.stringToNumber(this.mortgageAdviceFee)) {
                        contributionOfOwnFunds.mortgageAdviceFee = this.stringToNumber(this.mortgageAdviceFee)
                    }

                    if (this.notaryFee && this.stringToNumber(this.notaryFee)) {
                        contributionOfOwnFunds.notaryFee = this.stringToNumber(this.notaryFee)
                    }

                    if (this.maximumMortgage < this.formSchema.valueForLtv) {
                        contributionOfOwnFunds.minimumOwnFunds = this.formSchema.valueForLtv - this.maximumMortgage;
                    }

                    contributionOfOwnFunds.total =
                        contributionOfOwnFunds.minimumOwnFunds +
                        contributionOfOwnFunds.taxAmount +
                        contributionOfOwnFunds.brokerAmount +
                        contributionOfOwnFunds.appraisalFee +
                        contributionOfOwnFunds.mortgageAdviceFee +
                        contributionOfOwnFunds.notaryFee;

                    return contributionOfOwnFunds;
                }

                return {
                    total: 0
                };
            }
        },

        watch: {
            formSchema: {
                handler() {
                    this.getMaximumMortgage();
                },
                deep: true,
            },
        },
    });
</script>

<style scoped>
    @import '@/assets/main.css';
</style>
